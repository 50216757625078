<template>
  <div>
    <el-card>
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        class="tabs"
        value="allOrders"
      >
        <el-tab-pane
          :label="$t('financialCenter.modify')"
          name="allOrders"
          class="password"
        >
          <div class="top">
            <h1>{{ $t("financialCenter.setPassword") }}</h1>
            <h5>
              {{ $t("financialCenter.sokogatePassword") }}
            </h5>
          </div>
          <div class="main">
            <div id="phonePassword">
              <input
                type="password"
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                onafterpaste="this.value=this.value.replace(/[^\d]/g,'') "
              />
              <input
                type="password"
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                onafterpaste="this.value=this.value.replace(/[^\d]/g,'') "
              />
              <input
                type="password"
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                onafterpaste="this.value=this.value.replace(/[^\d]/g,'') "
              />
              <input
                type="password"
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                onafterpaste="this.value=this.value.replace(/[^\d]/g,'') "
              />
              <input
                type="password"
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                onafterpaste="this.value=this.value.replace(/[^\d]/g,'') "
              />
              <input
                type="password"
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                onafterpaste="this.value=this.value.replace(/[^\d]/g,'') "
              />
              <!-- <input
                type="number"
                maxlength="6"
                class="inputText"
                v-model="myPassWord"
                @input="bb"
              /> -->
            </div>
          </div>
          <div class="btn">
            <el-button class="button">{{
              $t("financialCenter.preservation")
            }}</el-button>
          </div>
        </el-tab-pane>

        <el-tab-pane
          :label="$t('financialCenter.forget')"
          name="confirmationPending"
          class="myIdentity"
        >
          <h2>{{ $t("financialCenter.myIdentity") }}</h2>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="200px"
            class="demo-ruleForm"
          >
            <el-form-item :label="$t('financialCenter.id')" prop="id">
              <el-input v-model="ruleForm.id"></el-input>
            </el-form-item>
            <el-form-item :label="$t('financialCenter.name')" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('financialCenter.certificate')"
              prop="certificate"
            >
              <el-input v-model="ruleForm.certificate"></el-input>
            </el-form-item>
            <el-form-item class="btns">
              <el-button class="next">{{
                $t("financialCenter.next")
              }}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "confirmationPending",
      myPassWord: "",
      ruleForm: {
        id: "ID",
        name: "",
        certificate: "",
      },
      rules: {
        id: [{ required: true, message: "请输入ID", trigger: "blur" }],
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        certificate: [
          { required: true, message: "请输入证件号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    bb() {
      if (this.myPassWord.length <= 6) {
        var passWord = document.querySelector("#phonePassword");
        for (let i = 0; i < 6; i++) {
          passWord.children[i].value = "";
        }
        for (let i = 0; i < this.myPassWord.length; i++) {
          passWord.children[i].value = "1";
        }
      } else if (this.myPassWord.length > 6) {
        this.myPassWord = this.myPassWord.slice(0, 6);
      }
    },
  },
};
</script>

<style scoped>
div .el-tabs__item:focus.is-active.is-focus:not(:active) {
  box-shadow: none;
}
.password {
  text-align: center;
}
.password .top {
  margin-top: 90px;
}
.password .top h1 {
  font-size: 20px;
}
.password .top h5 {
  font-size: 13px;
  margin-top: 20px;
}
.password .main {
  margin-top: 77px;
}
.main input {
  outline: none;
  width: 56px;
  height: 56px;
  font-size: 50px;
  border: 1px solid #dcdfe6;
  text-align: center;
  margin-right: 35px;
}
#phonePassword {
  position: relative;
  margin: auto;
}
/* #phonePassword input {
  width: 56px;
  height: 56px;
  border: 1px solid #000000;
  font-size: 50px;
  text-align: center;
} */
/* #phonePassword .inputText {
  background-color: transparent;
  color: transparent;
  outline: none;
  width: 360px;
  position: absolute;
  z-index: 1;
} */
.btn {
  margin-top: 161px;
}
.btn .button {
  width: 376px;
  height: 50px;
  border-radius: 6px;
  background: #ff0a0a;
  color: #fff;
  margin-bottom: 210px;
  margin-left: 20px;
}

.myIdentity h2 {
  margin-top: 90px;
  text-align: center;
  margin-left: -60px;
}
.myIdentity .demo-ruleForm {
  margin-top: 90px;
  margin-left: 25%;
}
div .el-input__inner {
  width: 40%;
}
.myIdentity .next {
  background: #ff0a0a;
  color: #fff;
  width: 40%;
  margin-top: 56px;
  border-radius: 6px;
  margin-bottom: 210px;
  height: 50px;
}
</style>